form .input_field .file {
  font-size: 17px;
  &__drop {
    background-color: #f0f0f0;
    border: 2px dotted #e6e6e6;
    position: relative;
    padding: 2.4rem;

    &:focus-within {
      border-style: solid;
    }

    &.has_file {
      border-color: rgba(68, 208, 124, 0.5);
      background-color: rgba(236, 253, 243, 0.5);
    }
  }
  .file-placeholder {
    justify-self: center;
    grid-area: placeholder;
    color: black;
  }
  .filename {
    grid-area: label;
    justify-self: flex-end;
    color: black;
  }
  &__remove {
    display: none;
  }

  &__overlay {
    pointer-events: none;
    padding: 0 1rem;
    text-align: center;
  }
  input {
    opacity: 0 !important;
    inset: 0;
    position: absolute;

    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .drag_over {
    border-style: dashed;
  }

  .has_file {
    .file__remove {
      display: flex;
    }
  }

  &__remove {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 2rem;
    padding: 0;
    transform: translateY(-50%);
    font-size: 14px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    transition: border-color 0.3s ease;
    &:hover {
      border-color: transparent;
    }
  }
}

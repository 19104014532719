// See colors in file: style.plate
$body_fontSize: var(--fontsize_body, 1.6rem);

// Transition
$transition: (
	ease: "ease-in-out",
	speed: 0.2s,
);

/*  Padding */
$padding: (
	small: 1rem,
	medium: 3rem,
	large: 5rem,
	column-mobile: 15px,
	column-desktop: 15px,
);
$minColumnHeight: 4rem;

// @see space.scss
$spaces-mobile: (
	s: 2.5rem,
	m: 5rem,
	l: 10rem,
);
$spaces-desktop: (
	s: map-get($spaces-mobile, s),
	m: map-get($spaces-mobile, m),
	l: map-get($spaces-mobile, l),
);

$sectionPadding: 5rem;
$smallFactor: 0.5;
$largeFactor: 2;

$max-sections: 20;

$mobileFactor: 0.5;

/* Breakpoints */
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	// xxl: 1400px,
	// xxxl: 1600px,
	// xxxxl: 1800px,
	// hd: 1920px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);
$bpDown: sm;
$bpUp: md;
$bpDownNav: $bpDown;
$bpUpNav: $bpUp;

/* Navigation */
$toggleWidth: 25px;
$toggleHeight: 25px;
$toggleLineHeight: 3px;
$toggleLineBorderRadius: 2px;
$toggleLineColor: black;
$toggleLineColorActive: black;

/* Swiper */
$swiper-theme-color: black;
$swiper-preloader-color: black;
$swiper-navigation-color: white;
$swiper-pagination-color: white;
$swiper-navigation-size: 44px;
$paginationColorName: "black";

// Z-index mapping;
// @see: styles/helpers/0-mixins.scss
$z-index: (
	modal: 200,
	navigation: 120,
	header: 115,
	map: 110,
	footer: 100,
);

.handles {
  &-label {
    .contact_form__field__error {
      font-weight: 400;
    }
  }
  &.input_field {
    border: none;
  }
  .radio-check {
    margin: 0 0 3.2rem;
  }
  &-wrapper {
    display: grid !important;
    gap: 1.5rem;
    min-height: unset !important;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s ease;
    opacity: 0;
    &.active {
      opacity: 1;
      grid-template-rows: 1fr;
    }
    &-inner {
      display: grid;
      gap: 1.5rem;
      overflow: hidden;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      @include media-breakpoint-up($bpUp) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
    }
    &.input_field {
      border: none;
    }
    .handle {
      position: relative;
      border: solid 1px black;
      cursor: pointer;
      picture {
        height: auto;
        img {
          width: 100%;
          position: relative;
        }
      }

      &-title {
        padding: 1rem;
        text-align: center;
        position: relative;
        transition: color 0.2s ease;
        overflow: hidden;
        font-size: 17px;
        border-top: solid black 1px;
        &::after {
          content: "";
          background-color: black;
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          z-index: -1;
          transform: translateY(-100%);
          transition: transform 0.2s ease;
        }
      }
      &.active,
      &:hover {
        .handle-title {
          color: white;
          &::after {
            transform: translateY(0%);
          }
        }
      }
    }
  }
}

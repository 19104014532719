.bgimage {
   background-size: cover;
   background-position: center center;
}

// .bg {
// 	@include color("background-color");
// }

// .color {
// 	@include color("color");
// }

@mixin pseudo-absolute($top: 0, $left: 0) {
  content: "";
  position: absolute;
  top: $top;
  left: $left;
}

@mixin pseudo-absolute-cover {
  @include pseudo-absolute;
  width: 100%;
  height: 100%;
}

@mixin cover($align: "unset") {
  position: absolute;

  @if $align == "right" or $align == "unset" {
    right: 0;
  } @else {
    left: 0;
  }

  @if $align == "top" or $align == "right" or $align == "left" or $align == "unset" {
    top: 0;
  } @else {
    bottom: 0;
  }

  @if $align == "top" or $align == "bottom" or $align == "unset" {
    width: 100%;
  }

  @if $align == "left" or $align == "right" or $align == "unset" {
    height: 100%;
  }
}

@mixin flex-c {
  display: flex;
  align-items: center;
}

@mixin flex-sc {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@mixin flex-ec {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@mixin flex-cc {
  @include flex-c;
  justify-content: center;
}

@mixin flex-cb {
  @include flex-c;
  justify-content: space-between;
}

@mixin flex-ce {
  @include flex-c;
  justify-content: flex-end;
}

@mixin flex-cs {
  @include flex-c;
  justify-content: flex-start;
}

@mixin flex-ca {
  @include flex-c;
  justify-content: space-around;
}

@mixin flexc-c {
  flex-direction: column;
  display: flex;
  align-items: center;
}

@mixin flexc-cc {
  flex-direction: column;
  @include flex-c;
  justify-content: center;
}

@mixin flexc-cb {
  flex-direction: column;
  @include flex-c;
  justify-content: space-between;
}

@mixin flexc-ce {
  flex-direction: column;
  @include flex-c;
  justify-content: flex-end;
}

@mixin flexc-cs {
  flex-direction: column;
  @include flex-c;
  justify-content: flex-start;
}

@mixin flexc-ca {
  flex-direction: column;
  @include flex-c;
  justify-content: space-around;
}

@mixin cover-img {
  @include cover;
  object-fit: cover;
}

@mixin transition($attribute: all, $speed: map-get($transition, "speed"), $ease: ease-in-out) {
  transition: $attribute $speed $ease;
}
@mixin multipleTransitions($attributes, $speed: map-get($transition, "speed"), $ease: ease-in-out) {
  $transition: ();
  @for $i from 1 through length($attributes) {
    $transition: append($transition, (nth($attributes, $i) $speed $ease), comma);
  }
  transition: $transition;
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin font-size($size, $base: 16) {
  font-size: $size; // fallback for old browsers
  font-size: math.div($size, $base) * 1rem;
}

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation== "vertical" {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation== "horizontal" {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

@mixin background-image() {
  background-size: cover;
  background-position: center center;
}

@mixin scroll() {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin imgLarge($size, $direction) {
  .image--large {
    margin-#{$direction}: -$size;
    height: calc(100% + (2 * #{$size}));
  }
}

// @mixin color($type) {
// 	@each $name, $code in $colors {
// 		&--#{$name} {
// 			#{$type}: $code !important;
// 		}
// 	}
// }

// This is a accessible way of 'hiding' something.
// @see: https://getbootstrap.com/docs/5.0/helpers/visually-hidden/
@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.visually-hidden {
  @include visually-hidden;
}

@mixin animation($animationName, $duration, $fillMode: "forwards") {
  animation-name: #{$animationName};
  animation-duration: #{$duration};
  animation-fill-mode: #{$fillMode};
}

@mixin verticalAnimation($animationName, $duration: 2s) {
  $from: 100%;
  $to: 0%;
  @if $animationName == "animationDown" {
    $from: -100%;
    $to: 0%;
  }
  animation-name: #{$animationName};
  animation-duration: #{$duration};
  animation-fill-mode: forwards;

  @keyframes #{$animationName} {
    from {
      opacity: 0;
      @if $animationName != "animationFade" {
        transform: translateY(#{$from});
      }
    }
    to {
      opacity: 1;
      @if $animationName != "animationFade" {
        transform: translateY(#{$to});
      }
    }
  }
}

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-primary, "GT-Walsheim");
}

ul {
  list-style: none;

  &,
  li {
    padding: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary, "GT-Walsheim");
}

@mixin color($type) {
   &_p {
      #{$type}: var(--primary-color);
   }
   &_s {
      #{$type}: var(--secondary-color);
   }
   &_t {
      #{$type}: var(--tertiary-color);
   }
   &_b {
      #{$type}: var(--black);
   }
   &_w {
      #{$type}: var(--white);
   }
   &_lg {
      #{$type}: var(--lightgrey);
   }
}
.tc {
   @include color("color");
}
.bgc {
   @include color("background-color");
}

// Defaults when no classes are added. CSS specificity makes sure that these default are overwritten.
.section {
	@include media-breakpoint-down($bpDown) {
		padding-top: map-get($spaces-mobile, m);
		padding-bottom: map-get($spaces-mobile, m);
	}

	@include media-breakpoint-up($bpUp) {
		padding-top: map-get($spaces-desktop, m);
		padding-bottom: map-get($spaces-desktop, m);
	}
}

// Classes that are set on the .section element.
// Example: .p-t-d--<variable>;
// Example: .p-b-m--<variable>;
@each $space, $unit in $spaces-mobile {
	// Padding
	.p {
		@include media-breakpoint-down($bpDown) {
			// Top Mobile
			&-t-m--#{$space} {
				padding-top: $unit;
			}
			// Bottom Mobile
			&-b-m--#{$space} {
				padding-bottom: $unit;
			}
		}
	}
}
@each $space, $unit in $spaces-desktop {
	// Padding
	.p {
		@include media-breakpoint-up($bpUp) {
			// Top Desktop
			&-t-d--#{$space} {
				padding-top: $unit;
			}
			// Bottom Desktop
			&-b-d--#{$space} {
				padding-bottom: $unit;
			}
		}
	}
}

/* VARIABLES */
/*  Padding */
/* Breakpoints */
/* Navigation */
/* Swiper */
/* HELPERS */
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Libs general */
/*  Padding */
/* Breakpoints */
/* Navigation */
/* Swiper */
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.animate-up {
  animation-name: animationUp;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes animationUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.bgimage {
  background-size: cover;
  background-position: center center;
}

.tc_p {
  color: var(--primary-color);
}
.tc_s {
  color: var(--secondary-color);
}
.tc_t {
  color: var(--tertiary-color);
}
.tc_b {
  color: var(--black);
}
.tc_w {
  color: var(--white);
}
.tc_lg {
  color: var(--lightgrey);
}

.bgc_p {
  background-color: var(--primary-color);
}
.bgc_s {
  background-color: var(--secondary-color);
}
.bgc_t {
  background-color: var(--tertiary-color);
}
.bgc_b {
  background-color: var(--black);
}
.bgc_w {
  background-color: var(--white);
}
.bgc_lg {
  background-color: var(--lightgrey);
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.f {
  display: flex;
}
.fw {
  flex-wrap: wrap;
}
.fwr {
  flex-wrap: wrap-reverse;
}
.fnw {
  flex-wrap: nowrap;
}
.f_c {
  flex-direction: column;
}
.f_cr {
  flex-direction: column-reverse;
}
.f_r {
  flex-direction: row;
}
.f_rr {
  flex-direction: row-reverse;
}
.f-c {
  align-items: center;
}
.f-fs {
  align-items: flex-start;
}
.f-fe {
  align-items: flex-end;
}
.f--fs {
  justify-content: flex-start;
}
.f--fe {
  justify-content: flex-end;
}
.f--c {
  justify-content: center;
}
.f--sb {
  justify-content: space-between;
}
.f--sa {
  justify-content: space-around;
}

@media (max-width: 767.98px) {
  .fm {
    display: flex;
  }
  .fmw {
    flex-wrap: wrap;
  }
  .fmwr {
    flex-wrap: wrap-reverse;
  }
  .fmnw {
    flex-wrap: nowrap;
  }
  .fm_c {
    flex-direction: column;
  }
  .fm_cr {
    flex-direction: column-reverse;
  }
  .fm_r {
    flex-direction: row;
  }
  .fm_rr {
    flex-direction: row-reverse;
  }
  .fm-c {
    align-items: center;
  }
  .fm-fs {
    align-items: flex-start;
  }
  .fm-fe {
    align-items: flex-end;
  }
  .fm--fs {
    justify-content: flex-start;
  }
  .fm--fe {
    justify-content: flex-end;
  }
  .fm--c {
    justify-content: center;
  }
  .fm--sb {
    justify-content: space-between;
  }
  .fm--sa {
    justify-content: space-around;
  }
}

@media (min-width: 768px) {
  .fd {
    display: flex;
  }
  .fdw {
    flex-wrap: wrap;
  }
  .fdwr {
    flex-wrap: wrap-reverse;
  }
  .fdnw {
    flex-wrap: nowrap;
  }
  .fd_c {
    flex-direction: column;
  }
  .fd_cr {
    flex-direction: column-reverse;
  }
  .fd_r {
    flex-direction: row;
  }
  .fd_rr {
    flex-direction: row-reverse;
  }
  .fd-c {
    align-items: center;
  }
  .fd-fs {
    align-items: flex-start;
  }
  .fd-fe {
    align-items: flex-end;
  }
  .fd--fs {
    justify-content: flex-start;
  }
  .fd--fe {
    justify-content: flex-end;
  }
  .fd--c {
    justify-content: center;
  }
  .fd--sb {
    justify-content: space-between;
  }
  .fd--sa {
    justify-content: space-around;
  }
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-primary, "GT-Walsheim");
}

ul {
  list-style: none;
}
ul,
ul li {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary, "GT-Walsheim");
}

picture {
  position: relative;
}
picture source {
  position: absolute;
  top: 0;
}

@media (max-width: 767.98px) {
  .section {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 768px) {
  .section {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (max-width: 767.98px) {
  .p-t-m--s {
    padding-top: 2.5rem;
  }
  .p-b-m--s {
    padding-bottom: 2.5rem;
  }
}

@media (max-width: 767.98px) {
  .p-t-m--m {
    padding-top: 5rem;
  }
  .p-b-m--m {
    padding-bottom: 5rem;
  }
}

@media (max-width: 767.98px) {
  .p-t-m--l {
    padding-top: 10rem;
  }
  .p-b-m--l {
    padding-bottom: 10rem;
  }
}

@media (min-width: 768px) {
  .p-t-d--s {
    padding-top: 2.5rem;
  }
  .p-b-d--s {
    padding-bottom: 2.5rem;
  }
}

@media (min-width: 768px) {
  .p-t-d--m {
    padding-top: 5rem;
  }
  .p-b-d--m {
    padding-bottom: 5rem;
  }
}

@media (min-width: 768px) {
  .p-t-d--l {
    padding-top: 10rem;
  }
  .p-b-d--l {
    padding-bottom: 10rem;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
picture,
picture img {
  display: block;
}
picture source {
  display: block;
  width: 100%;
  height: 100%;
}

.rel {
  position: relative;
}

form .input_field .file {
  font-size: 17px;
}
form .input_field .file__drop {
  background-color: #f0f0f0;
  border: 2px dotted #e6e6e6;
  position: relative;
  padding: 2.4rem;
}
form .input_field .file__drop:focus-within {
  border-style: solid;
}
form .input_field .file__drop.has_file {
  border-color: rgba(68, 208, 124, 0.5);
  background-color: rgba(236, 253, 243, 0.5);
}
form .input_field .file .file-placeholder {
  justify-self: center;
  grid-area: placeholder;
  color: black;
}
form .input_field .file .filename {
  grid-area: label;
  justify-self: flex-end;
  color: black;
}
form .input_field .file__remove {
  display: none;
}
form .input_field .file__overlay {
  pointer-events: none;
  padding: 0 1rem;
  text-align: center;
}
form .input_field .file input {
  opacity: 0 !important;
  inset: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 0;
}
form .input_field .file .drag_over {
  border-style: dashed;
}
form .input_field .file .has_file .file__remove {
  display: flex;
}
form .input_field .file__remove {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 2rem;
  padding: 0;
  transform: translateY(-50%);
  font-size: 14px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  transition: border-color 0.3s ease;
}
form .input_field .file__remove:hover {
  border-color: transparent;
}

.handles-label .contact_form__field__error {
  font-weight: 400;
}
.handles.input_field {
  border: none;
}
.handles .radio-check {
  margin: 0 0 3.2rem;
}
.handles-wrapper {
  display: grid !important;
  gap: 1.5rem;
  min-height: unset !important;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease;
  opacity: 0;
}
.handles-wrapper.active {
  opacity: 1;
  grid-template-rows: 1fr;
}
.handles-wrapper-inner {
  display: grid;
  gap: 1.5rem;
  overflow: hidden;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
@media (min-width: 576px) {
  .handles-wrapper-inner {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .handles-wrapper-inner {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .handles-wrapper-inner {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
.handles-wrapper.input_field {
  border: none;
}
.handles-wrapper .handle {
  position: relative;
  border: solid 1px black;
  cursor: pointer;
}
.handles-wrapper .handle picture {
  height: auto;
}
.handles-wrapper .handle picture img {
  width: 100%;
  position: relative;
}
.handles-wrapper .handle-title {
  padding: 1rem;
  text-align: center;
  position: relative;
  transition: color 0.2s ease;
  overflow: hidden;
  font-size: 17px;
  border-top: solid black 1px;
}
.handles-wrapper .handle-title::after {
  content: "";
  background-color: black;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  transform: translateY(-100%);
  transition: transform 0.2s ease;
}
.handles-wrapper .handle.active .handle-title, .handles-wrapper .handle:hover .handle-title {
  color: white;
}
.handles-wrapper .handle.active .handle-title::after, .handles-wrapper .handle:hover .handle-title::after {
  transform: translateY(0%);
}

.notification {
  position: relative;
}
.notification .message {
  border: 1px solid transparent;
  border-radius: 0.4rem;
  background-color: transparent;
  height: 0;
  opacity: 0;
  transition: all 0.1s ease-out;
  transform-style: preserve-3d;
  transform: rotateX(90deg) scaleY(0);
}
.notification .message p {
  padding: 1.2rem 1.6rem;
}
.notification.forgotten .message__forgotten {
  height: auto;
  opacity: 1;
  transform: rotateX(0deg) scaleY(1);
}
.notification.error .message__error {
  height: auto;
  opacity: 1;
  transform: rotateX(0deg) scaleY(1);
}
.notification.success .message__success {
  height: auto;
  opacity: 1;
  transform: rotateX(0deg) scaleY(1);
}
.notification:not(.forgotten):not(.error):not(.success) {
  position: absolute;
}
.notification .message__success {
  border-color: rgb(68, 208, 124);
  background-color: rgb(236, 253, 243);
}
.notification .message__forgotten,
.notification .message__error {
  border-color: rgb(255, 84, 84);
  background-color: rgb(255, 240, 240);
}

.quotation-step-wrapper .quotation-steps {
  display: flex;
  overflow: auto;
}
.quotation-step-wrapper .quotation-fields {
  display: grid;
  gap: 0 2rem;
  margin: 0 0 4rem;
  grid-template-columns: minmax(0, 1fr);
}
@media (min-width: 576px) {
  .quotation-step-wrapper .quotation-fields {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.quotation-step-wrapper .quotation-fields .field-wrapper {
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
  margin-bottom: 30px;
  flex-direction: column;
}
@media (min-width: 576px) {
  .quotation-step-wrapper .quotation-fields .field-wrapper.half {
    grid-column: span 1;
  }
}
.quotation-step-wrapper .quotation-fields .input_field {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}
.quotation-step-wrapper .quotation-fields .input_field.color .own-color-wrapper {
  margin: 2rem 0 0;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease;
  opacity: 0;
}
.quotation-step-wrapper .quotation-fields .input_field.color .own-color-wrapper.active {
  opacity: 1;
  grid-template-rows: 1fr;
}
.quotation-step-wrapper .quotation-fields .input_field.color .own-color-wrapper-inner {
  overflow: hidden;
}
.quotation-step-wrapper .quotation-fields .input_field.color .own-color-wrapper-inner .field-wrapper {
  margin: 0;
}
.quotation-step-wrapper .quotation-step-content {
  display: grid;
  padding: 0 2rem;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease;
}
.quotation-step-wrapper .quotation-step-content-wrapper {
  overflow: hidden;
  transition: padding 0.3s ease;
}
.quotation-step-wrapper .quotation-step-content.active {
  grid-template-rows: 1fr;
}
.quotation-step-wrapper .quotation-step-content.active .quotation-step-content-wrapper {
  padding: 5rem 0;
}
.quotation-step-wrapper .quotation-step-content .preview-fields {
  margin: 0 0 4rem;
}
.quotation-step-wrapper .quotation-step-content .preview-fields .preview-step {
  font-size: 17px;
  margin: 0 0 1.5rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.3);
  padding: 2.5rem 0;
}
.quotation-step-wrapper .quotation-step-content .preview-fields .preview-step__title {
  font-size: 18px;
  margin: 0 0 1rem;
  font-weight: 600;
}
.quotation-step-wrapper .quotation-step-content .preview-fields .preview-step__fields {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}
@media (min-width: 576px) {
  .quotation-step-wrapper .quotation-step-content .preview-fields .preview-step__fields {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.quotation-step-wrapper .quotation-step-content .preview-fields .preview-step__fields .preview-field {
  margin: 0 0 1.5rem;
}
.quotation-step-wrapper .quotation-step-content .preview-fields .preview-step__fields .preview-field__label {
  display: block;
  font-weight: 600;
}
.quotation-step-wrapper .quotation-step-title {
  padding: 1rem 2rem;
  text-transform: uppercase;
  font-size: 17px;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: 600;
  border-bottom: solid 2px white;
}
.quotation-step-wrapper .quotation-step-title.active {
  border-bottom: solid 2px black;
}
.quotation-step-wrapper .quotation-step-title:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
.quotation-step-wrapper .quotation-step-nav {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 17px;
}
.quotation-step-wrapper .contact_form__field__error {
  font-size: 12px;
  color: red;
  margin: 5px 0 0;
}
.quotation-step-wrapper select {
  font-size: 17px;
}
.quotation-step-wrapper .submit_button {
  margin: 0;
}
.quotation-step-wrapper .submit_button[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
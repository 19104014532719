.quotation-step-wrapper {
  .quotation-steps {
    display: flex;
    overflow: auto;
  }
  .quotation-fields {
    display: grid;
    gap: 0 2rem;
    margin: 0 0 4rem;
    grid-template-columns: minmax(0, 1fr);
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .field-wrapper {
      grid-column: span 2;
      display: flex;
      justify-content: flex-end;
      padding: 20px 0 0;
      margin-bottom: 30px;
      flex-direction: column;
      &.half {
        @include media-breakpoint-up(sm) {
          grid-column: span 1;
        }
      }
    }
    .input_field {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      &.color {
        .own-color-wrapper {
          margin: 2rem 0 0;
          display: grid;
          grid-template-rows: 0fr;
          transition: grid-template-rows 0.3s ease;
          opacity: 0;
          &.active {
            opacity: 1;
            grid-template-rows: 1fr;
          }
          &-inner {
            overflow: hidden;
            .field-wrapper {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .quotation-step {
    &-content {
      display: grid;
      padding: 0 2rem;
      grid-template-rows: 0fr;
      transition: grid-template-rows 0.3s ease;
      &-wrapper {
        overflow: hidden;
        transition: padding 0.3s ease;
      }
      &.active {
        grid-template-rows: 1fr;
        .quotation-step-content-wrapper {
          padding: 5rem 0;
        }
      }
      .preview-fields {
        margin: 0 0 4rem;
        .preview-step {
          font-size: 17px;
          margin: 0 0 1.5rem;
          border-bottom: solid 1px rgba(0, 0, 0, 0.3);
          padding: 2.5rem 0;
          &__title {
            font-size: 18px;
            margin: 0 0 1rem;
            font-weight: 600;
          }
          &__fields {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            @include media-breakpoint-up(sm) {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }
            .preview-field {
              margin: 0 0 1.5rem;
              &__label {
                display: block;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    &-title {
      padding: 1rem 2rem;
      text-transform: uppercase;
      font-size: 17px;
      white-space: nowrap;
      transition:
        background-color 0.3s ease,
        color 0.3s ease;
      font-weight: 600;
      border-bottom: solid 2px white;
      &.active {
        border-bottom: solid 2px black;
      }
      &:hover {
        background-color: #e6e6e6;
        cursor: pointer;
      }
    }
    &-nav {
      display: flex;
      justify-content: center;
      gap: 10px;
      font-size: 17px;
    }
  }
  .contact_form__field__error {
    font-size: 12px;
    color: red;
    margin: 5px 0 0;
  }
  select {
    font-size: 17px;
  }
  .submit_button {
    margin: 0;
    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
